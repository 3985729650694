<template>
  <div>
    <div class="row">
      <div class="col-sm-3 col-md-3 col-lg-3">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 훈련 시나리오 목록 -->
          <c-table
            ref="table"
            title="LBL0002637"
            :columns="grid.columns"
            :data="grid.data "
            :gridHeight="grid.height"
            :filtering="false"
            :isExcelDown="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="editable&&!disabled"
            rowKey="trainingScenarioDescNo"
            @rowClick="rowClick"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <!-- 시나리오 복사 -->
                <c-btn v-if="editable" label="LBL0002638" icon="save_alt" @btnClicked="copyScenario" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
      <div class="col-9">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <q-form ref="editForm">
              <!-- 훈련 시나리오 상세 -->
              <c-card title="LBL0002639" class="cardClassDetailForm" topClass="topcolor-blue">
                <template slot="card-button">
                  <q-btn-group outline >
                    <!-- 신규시나리오 -->
                    <c-btn v-if="editable" label="LBL0002640" icon="add" @btnClicked="addScenario" />
                    <!-- 개정 -->
                    <c-btn
                      v-show="(editable && traning.trainingScenarioNo && !isRevision) && !disabled"
                      label="LBLREV"
                      icon="restart_alt"
                      @btnClicked="setRevision" />
                    <!-- 개정취소 -->
                    <c-btn
                      v-show="(editable && traning.trainingScenarioNo && isRevision) && !disabled"
                      label="LBLREVCANCEL"
                      icon="restart_alt"
                      @btnClicked="cancelRevision" />
                      <!-- (훈련시나리오 일괄)저장 -->
                    <c-btn
                      v-if="!disabled && editable"
                      :url="saveUrl"
                      :isSubmit="isSave"
                      :param="traning"
                      :mappingType="saveType"
                      label="LBL0002641"
                      icon="save"
                      @beforeAction="saveScenario"
                      @btnCallback="saveCallback" 
                    />
                  </q-btn-group>
                </template>
                <template slot="card-detail">
                  <div class="col-sm-12 col-md-9 col-lg-9">
                    <!-- 훈련시나리오명 -->
                    <c-text
                      required
                      :editable="editable"
                      :disabled="disabled"
                      label="LBL0002609"
                      name="trainingScenarioName"
                      v-model="traning.trainingScenarioName">
                    </c-text>
                  </div>
                  <div class="col-sm-6 col-md-3 col-lg-3">
                    <!-- 사용여부 -->
                    <c-radio
                      :editable="editable"
                      :disabled="disabled"
                      :comboItems="useFlagItems"
                      label="LBLUSEFLAG"
                      name="useFlag"
                      v-model="traning.useFlag">
                    </c-radio>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <!-- 훈련내용요약 -->
                    <c-textarea
                      required
                      :editable="editable"
                      :disabled="disabled"
                      :rows="4"
                      :autogrow="false"
                      label="LBL0002610"
                      name="trainingContentsSummary"
                      v-model="traning.trainingContentsSummary">
                    </c-textarea>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <!-- 훈련시나리오개요 -->
                    <c-textarea
                      :editable="editable"
                      :disabled="disabled"
                      :rows="4"
                      :autogrow="false"
                      label="LBL0002642"
                      name="trainingContentsOutline"
                      v-model="traning.trainingContentsOutline">
                    </c-textarea>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <!-- 직접참가자 유의사항 -->
                    <c-textarea
                      :editable="editable"
                      :disabled="disabled"
                      :rows="4"
                      :autogrow="false"
                      label="LBL0002643"
                      name="directlyNotice"
                      v-model="traning.directlyNotice">
                    </c-textarea>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <!-- 간접참가자 유의사항 -->
                    <c-textarea
                      :editable="editable"
                      :disabled="disabled"
                      :rows="4"
                      :autogrow="false"
                      label="LBL0002644"
                      name="indirectNotice"
                      v-model="traning.indirectNotice">
                    </c-textarea>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <!-- 준비물 -->
                    <c-textarea
                      :editable="editable"
                      :disabled="disabled"
                      :rows="4"
                      :autogrow="false"
                      label="LBL0002645"
                      name="materials"
                      v-model="traning.materials">
                    </c-textarea>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <!-- 사전준비사항 -->
                    <c-textarea
                      :editable="editable"
                      :disabled="disabled"
                      :rows="4"
                      :autogrow="false"
                      label="LBL0002646"
                      name="preRequisites"
                      v-model="traning.preRequisites">
                    </c-textarea>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <!-- 피해반경 -->
                    <c-upload 
                      :editable="editable&&!disabled"
                      label="LBL0002647">
                    </c-upload>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <!-- 비상대응훈련시나리오 -->
                    <c-upload 
                      :editable="editable&&!disabled"
                      label="LBL0002648">
                    </c-upload>
                  </div>
                </template>
              </c-card>
            </q-form>
          </div>
          <div class="col-6">
            <!-- 훈련 상세 목록 -->
            <c-table
              ref="table"
              topBorderClass="topcolor-blue"
              title="LBL0002649"
              :columns="gridDetail.columns"
              :data="traning.emergencyScenarioDetailModelList"
              :gridHeight="gridDetail.height"
              :filtering="false"
              :isExcelDown="false"
              :usePaging="false"
              :columnSetting="false"
              :editable="editable&&!disabled"
              selection="multiple"
              rowKey="trainingScenarioDescNo"
            >
              <!-- 버튼 영역 -->
              <template slot="table-button">
                <q-btn-group outline >
                  <!-- 추가 -->
                  <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add_circle" @btnClicked="addScenarioDetail" />
                  <!-- 제외 -->
                  <c-btn v-if="editable&&!disabled && traning.emergencyScenarioDetailModelList.length > 0" label="LBLEXCEPT" icon="remove" @btnClicked="removeScenarioDetail" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
        </div>
        <!-- 개정 -->
        <c-card title="LBLREV" class="cardClassDetailForm" topClass="topcolor-orange" v-if="traning.trainingScenarioNo">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="row">
                <div class="col-4">
                  <!-- 개정번호 -->
                  <c-text
                    :required="editable && isRevision"
                    :editable="editable && isRevision"
                    label="LBLREVNO"
                    name="revisionNum"
                    v-model="traning.revisionNum">
                  </c-text>
                </div>
                <div class="col-4">
                  <!-- 개정기간 -->
                  <c-text
                    :editable="false"
                    label="LBLREVPERIOD"
                    name="regDtStr"
                    v-model="traning.regDtStr">
                  </c-text>
                </div>
                <div class="col-4">
                  <!-- 개정자 -->
                  <c-text
                    :editable="false"
                    label="LBLREVUSER"
                    name="regUserName"
                    v-model="traning.regUserName">
                  </c-text>
                </div>
                <div class="col-12">
                  <!-- 개정사유 -->
                  <c-textarea
                    :required="editable && isRevision"
                    :editable="editable && isRevision"
                    label="LBLREVREASON"
                    :autogrow="false"
                    :rows="4"
                    name="revisionContent"
                    v-model="traning.revisionContent">
                  </c-textarea>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: -18px !important;" id="revdiv">
              <!-- 개정이력 -->
              <c-table
                ref="revision"
                title="LBL0002650"
                tableId="revision"
                topBorderClass="topcolor-orange"
                :columns="gridrev.columns"
                :data="gridrev.data"
                gridHeight="210px"
                :columnSetting="false"
                :expandAll="false"
                :isFullScreen="false"
                :usePaging="false"
                :isExcelDown="false"
                :hideBottom="true"
                :filtering="false"
                @rowClick="rowClickRev"
              >
              </c-table>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'traning-scenario',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopEmergencyPlanId: '',
      }),
    },
  },
  data() {
    return {
      traning: {
        sopEmergencyPlanId: '',
        trainingScenarioNo: '',
        revNo: 1,
        trainingScenarioName: '',
        trainingContentsSummary: '',
        trainingContentsOutline: '',
        directlyNotice: '',
        indirectNotice: '',
        materials: '',
        preRequisites: '',
        revRemark: '',
        revisionContent: '제정',
        revisionNum: '',
        groupId: '',
        regUserId: '',
        chgUserId: '',

        emergencyScenarioDetailModelList: [], // 훈련시나리오 상세
        deleteEmergencyScenarioDetailModelList: [], // 훈련시나리오 상세
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      grid: {
        columns: [
          {
            name: 'trainingScenarioName',
            field: 'trainingScenarioName',
            // 훈련시나리오명
            label: 'LBL0002609',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          // {
          //   name: 'revisionNum',
          //   field: 'revisionNum',
          //   label: 'Rev.',
          //   align: 'center',
          //   style: 'width:80px',
          //   sortable: false,
          // },
        ],
        height: '820px',
        data: [],
      },
      gridDetail: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            // 정렬순서
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width:8%',
            sortable: false,
            type: 'number'
          },
          {
            name: 'leadTime',
            field: 'leadTime',
            // 소요시간
            label: 'LBL0002651',
            align: 'center',
            minuteStep: 10,
            style: 'width:15%',
            sortable: false,
            type: 'datetime'
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: 'LBLITEMS',
            align: 'center',
            style: 'width:25%',
            sortable: false,
            type: 'text'
          },
          {
            name: 'trainingDetailContents',
            field: 'trainingDetailContents',
            // 상세훈련내용
            label: 'LBL0002652',
            align: 'left',
            style: 'width:40%',
            sortable: false,
            type: 'textarea'
          },
          // {
          //   name: 'manageDeptName',
          //   field: 'manageDeptName',
          //   label: '담당부서',
          //   align: 'center',
          //   style: 'width:90px',
          //   sortable: false,
          // },
          {
            name: 'managerUserName',
            field: 'managerUserName',
            // 관리자
            label: 'LBLMANAGER',
            align: 'center',
            style: 'width:15%',
            userId: 'managerUserId',
            sortable: false,
            type: 'user'
          },
        ],
        height: '525px',
        data: [],
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            // 개정번호
            label: 'LBLREVNO',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            // 개정사유
            label: 'LBLREVREASON',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            // 개정기간
            label: 'LBLREVPERIOD',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 개정자
            label: 'LBLREVUSER',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      disabled: true,
      editable: true,
      saveUrl: '',
      saveType: 'POST',
      isSave: false,
      getUrl: '',
      revlistUrl: '',
      getRevUrl: '',
      isRevision: false,
      realKey: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.eap.scenario.get.url;
      this.saveUrl = transactionConfig.sop.eap.scenario.insert.url;
      this.deleteUrl = transactionConfig.sop.eap.scenario.delete.url;
      this.revlistUrl = selectConfig.sop.eap.scenario.rev.url;
      this.getRevUrl = selectConfig.sop.eap.scenario.rev.get.url;
      // code setting
      // this.addScenario();
      this.getDetail(true);
    },
    getDetail(_check) {
      if (_check) {
        this.isRevision = false;
        if (this.param.sopEmergencyPlanId) {
          this.$http.url = this.$format(this.getUrl, this.param.sopEmergencyPlanId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
              this.grid.data = _result.data;
              
              if (this.realKey) {
                this.rowClick(this.$_.find(this.grid.data, { trainingScenarioNo: this.realKey }))
              }
          },);
        } 
      } else {
        this.$http.url = this.$format(this.getRevUrl, this.param.sopEmergencyPlanId, this.traning.trainingScenarioNo);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data) {
            this.traning = _result.data;
            if (_result.data.trainingScenarioNo === this.realKey) {
              this.disabled = false;
            } else {
              this.disabled = true;
            }
          }
        },);
      }
    },
    rowClick(row) {
      this.disabled = false;
      this.traning = this.$_.clone(row);
      // key 복사 (editable 체크)
      this.realKey = this.$_.cloneDeep(row.trainingScenarioNo)
      this.getRevList(row.groupId);
    },
    saveScenario() {
      if (this.traning.trainingScenarioNo) {
        this.saveUrl = transactionConfig.sop.eap.scenario.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.eap.scenario.insert.url;
        this.saveType = 'POST';
      }
      let saveMsg = 'MSGSAVE'; // 저장하시겠습니까?
      if (this.isRevision) {
        saveMsg = 'MSGREVSAVE';
        // 현재버전을 개정하여 저장하시겠습니까?
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.traning.regUserId = this.$store.getters.user.userId
              this.traning.chgUserId = this.$store.getters.user.userId
              this.traning.sopEmergencyPlanId = this.param.sopEmergencyPlanId
              
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.saveType = 'POST';
                this.traning.revNo = parseInt(this.traning.revNo) + 1;
              }
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.realKey = _result.data
      this.getDetail(true);
    },
    addScenarioDetail() {
      let maxNum = this.$_.maxBy(this.traning.emergencyScenarioDetailModelList, 'sortOrder');
      maxNum = maxNum && !isNaN(maxNum.sortOrder) ? Number(maxNum.sortOrder) + 1 : 1;
      uuidv4()
      this.traning.emergencyScenarioDetailModelList.push({
        sopEmergencyPlanId: this.param.sopEmergencyPlanId,
        trainingScenarioNo: this.traning.trainingScenarioNo ? this.traning.trainingScenarioNo : '',
        revNo: this.traning.revNo,
        trainingScenarioDescNo: uuidv4(),
        sortOrder: maxNum,
        leadTime: '',
        itemName: '',
        trainingDetailContents: '',
        managerUserId: '',
        managerUserName: '',
        managerDeptName: '',
        editFlag: 'C',
      })
    },
    removeScenarioDetail() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.traning.deleteEmergencyScenarioDetailModelList) {
            this.traning.deleteEmergencyScenarioDetailModelList = []
          }
          if (this.$_.findIndex(this.traning.deleteEmergencyScenarioDetailModelList, { trainingScenarioDescNo: item.trainingScenarioDescNo }) === -1
            && item.editFlag !== 'C') {
              this.traning.deleteEmergencyScenarioDetailModelList.push(item)
          }
          this.traning.emergencyScenarioDetailModelList = this.$_.reject(this.traning.emergencyScenarioDetailModelList, { trainingScenarioDescNo: item.trainingScenarioDescNo })
        })
        
      }
    },
    setRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.traning.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.traning.revisionNum = _result.data;
        this.traning.revisionContent = '';
        this.traning.regDtStr = '';
        this.traning.regUserName = '';
        this.isRevision = true;
        document.getElementById('revdiv').scrollIntoView();
      });
    },
    cancelRevision() {
      this.isRevision = false;
      this.getDetail(true);
    },
    rowClickRev(row) {
      if (row.trainingScenarioNo != this.traning.trainingScenarioNo) {
        this.traning.trainingScenarioNo = row.trainingScenarioNo
        this.getDetail(false);
      }
    },
    addScenario() {
      this.disabled = false;

      this.traning.trainingScenarioNo = '';
      this.traning.sopEmergencyPlanId = this.param.sopEmergencyPlanId;
      this.traning.revNo = 1;
      this.traning.trainingScenarioName = '';
      this.traning.trainingContentsSummary = '';
      this.traning.trainingContentsOutline = '';
      this.traning.directlyNotice = '';
      this.traning.indirectNotice = '';
      this.traning.materials = '';
      this.traning.preRequisites = '';
      this.traning.revRemark = '';
      this.traning.revisionContent = '제정';
      this.traning.revisionNum = '';
      this.traning.groupId = '';
      this.traning.preRequisites = '';
      this.traning.regUserId = '';
      this.traning.chgUserId = '';
      this.traning.emergencyScenarioDetailModelList = [];
      this.traning.deleteEmergencyScenarioDetailModelList = [];
      this.traning.useFlag = 'Y';
    },
    copyScenario() {
      this.popupOptions.title = 'LBL0002653'; // 복사 할 시나리오 검색
      this.popupOptions.param = {
        type: 'single',
        trainingScenarioNo: this.realKey
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/eap/traningScenarioCopyPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyPopup;
    },
    closeCopyPopup(data) {
      this.addScenario();
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        
        this.traning.emergencyScenarioDetailModelList = [];

        this.traning.indirectNotice = data[0].indirectNotice
        this.traning.directlyNotice = data[0].directlyNotice
        this.traning.preRequisites = data[0].preRequisites
        this.traning.materials = data[0].materials
        this.traning.trainingContentsOutline = data[0].trainingContentsOutline
        this.traning.trainingContentsSummary = data[0].trainingContentsSummary
        this.traning.trainingScenarioName = data[0].trainingScenarioName
        this.$_.forEach(data[0].emergencyScenarioDetailModelList, item => {
          this.traning.emergencyScenarioDetailModelList.push({
            sopEmergencyPlanId: this.param.sopEmergencyPlanId,
            trainingScenarioNo: this.traning.trainingScenarioNo ? this.traning.trainingScenarioNo : '',
            revNo: this.traning.revNo,
            trainingScenarioDescNo: uuidv4(),
            sortOrder: item.sortOrder,
            leadTime: item.leadTime,
            itemName: item.itemName,
            trainingDetailContents: item.trainingDetailContents,
            managerUserId: item.managerUserId,
            managerUserName: item.managerUserName,
            managerDeptName: '',
          })
        })
      }
    },
  }
};
</script>
